<template>
	<BaseLoading v-if="installment.edit.isLoading" is-full-page />
	<div v-else>
		<FormKTCInstallmentPlan
			:interest="data.interestRate"
			:period="data.period"
			:name="data.name"
			:min-order-amount="data.minOrderAmount"
			:max-order-amount="data.maxOrderAmount"
			:is-submitting="installment.edit.isUpdating"
			:group-id="$route.params.id"
			:method-id="$route.params.methodId"
			:plan-id="$route.params.planId"
			:custom-attributes="data.customAttributes"
			:available-skus="data.availableSkus"
			:period-status="data.periodValue"
			:start-date="data.startDate"
			:start-time="data.startTime"
			:end-date="data.endDate"
			:end-time="data.endTime"
			is-edit
			@onSubmit="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormKTCInstallmentPlan from '@/components/FormKTCInstallmentPlan.vue';

import { ROUTE_NAME } from '../enums/route';
import { HTTP_STATUS_CODE } from '../enums/http';

export default {
	name: 'KTCInstallmentPlanEdit',

	components: {
		FormKTCInstallmentPlan,
	},

	computed: {
		...mapGetters({
			data: 'payments/installmentDetails',
		}),

		...mapState('payments', ['installment']),
	},

	async created() {
		try {
			await this.getPlan({
				methodId: this.$route.params.methodId,
				planId: this.$route.params.planId,
			});

			// Set breadcrumb
			const bankName = this.data.paymentMethod.name;
			this.setBreadcrumbs([
				{
					title: 'Payment settings',
					route: { name: 'PaymentList' },
				},
				{
					title: `Installment credit card ${bankName}`,
					route: {
						name: 'PaymentMethodSetting',
						params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
					},
				},
				{
					title: 'Installment plan list',
					route: {
						name: 'KTCInstallmentPlanList',
					},
				},
			]);
		} catch (error) {
			if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) {
				this.$router.push({ name: ROUTE_NAME.PAGE_NOT_FOUND });
			}
		}
	},

	methods: {
		...mapActions({
			getPlan: 'payments/getInstallmentPlan',
			updatePlan: 'payments/updateInstallmentPlan',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
			getPaymentMethod: 'payments/getPaymentMethod',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const planId = this.$route.params.planId;

			await this.updatePlan({
				methodId,
				planId,
				params,
			});
		},
	},
};
</script>
